import React from "react"
import Layout from "@src/components/Layout"
import Callout from "@src/components/Callout"
import Row from "../components/Row"
import PostCard from "./components/PostCard"
import * as styles from "./BlogPost.module.scss"

const BlogList = ({ location, pageContext }) => {
  const baseUrl =
    process.env.NODE_ENV === "staging"
      ? "https://staging.canonic.dev"
      : "https://canonic.dev"

  const { og } = pageContext?.post || {}

  return (
    <Layout
      title={"Blog"}
      description={
        "Learn more about low-code, internal tools, Canonic, and more"
      }
      url={`${baseUrl}/blog`}
      location={location}
      ogType="blog"
    >
      <Row className={styles.blogList}>
        <div style={{ maxWidth: 10000, margin: "0 auto" }}>
          {/* {pageContext?.posts
            ?.reduce((arr, post, i) => {
              if (i % 6 === 0) arr.push([])
              arr[arr.length - 1].push(post)
              return arr
            }, [])
            ?.map(posts => (
              <Block posts={posts} />
            ))} */}
          <Block posts={pageContext?.posts} />
        </div>
      </Row>
      <Callout black />
    </Layout>
  )
}

function Block({ posts = [] }) {
  const heroPost = posts[0]
  const morePosts = posts.slice(1) || []
  return (
    <>
      <PostCard isDark post={heroPost} isHero />
      <ul className={styles.grid}>
        {morePosts.map(post => (
          <PostCard isDark post={post} />
        ))}
      </ul>
    </>
  )
}

export default BlogList
